
.buyWizard-steps {
    position: absolute;
    /* top: 50%;   */
    left: 50%; 
    width:100%;
    height:100%;
    transform: translate(-50%, 0%); 
    /* transform: translate(-50%, -50%);  */
}
.buyWizard-steps > div{
    /* top:20%; */
    position: relative;;
}
.buyWizard-enter {
    opacity: 0.01;
}
  
.buyWizard-enter.buyWizard-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.buyWizard-exit {
    opacity: 1;
}

.buyWizard-exit.buyWizard-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

/* disable the highlighting of text in the house selector  */
#wizard div.custom-control.custom-radio label{
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;   
  }