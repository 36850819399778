.mapboxgl-canvas{
    left:0px;
}
#mapbox {
    position: relative;
 }

 
.map-tool-container .mapbox-container {
    position: relative;
    height: 100%;
    width: 100vw;
}


.map-tool-container .map-header {
    position: relative;
    height: 100%;
    width: 100vw;
}


.mapboxgl-popup {
    max-width: 80vw;
    width: 300px;
    font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
    }
.mapboxgl-popup #my-react-card-popup .card{
    max-height:40vh;
    overflow:auto;
}